@import '_variables';
/* base styles*/
*,*:before, *:after{box-sizing:border-box;outline:none;}
*,body,html{-webkit-text-size-adjust: none;-webkit-appearance: none;}
html{height:100%;}
body{min-height:100%;width:100%;min-width:320px;font-family:'Gotham Pro',arial,sans-serif;background:#fff;color:$black;position:relative;font-size: 14px;line-height: 1;}
img{vertical-align:bottom;border:0;max-width:100%;}
ul{list-style: none;margin:0;padding: 0;}
/* helping adds*/
.cfix:after{content: '';display: block;height: 0;font-size: 1px;line-height: 1px;overflow: hidden;clear: both;}
.hidden-block{position:absolute;left:-99999em;top:-99999em;}

html,body{
    &.scroll-lock{overflow: hidden;}
}
/* /helping adds*/

/* wraps styling*/

.mbox{max-width:1430px;padding:0 15px;width:100%;margin:0 auto;position:relative;z-index:1;
    &.big{max-width: 1750px;}
    &.mini{max-width: 1030px;}
}

/* /wraps styling*/
/*fonts*/

@mixin font-face($style-name, $file:$style-name, $weight:normal, $style:normal) {
    $filepath: "../fonts/" + $file;
    @font-face {
        font-family: quote($style-name);
        src: url($filepath + ".eot");
        src: url($filepath + ".eot?#iefix") format('embedded-opentype'),
        url($filepath + ".woff") format('woff'),
        url($filepath + ".ttf")  format('truetype'),
        url($filepath + ".svg#" + $style-name + "") format('svg');
        font-weight: $weight;
        font-style: $style;
    }
}

//  example->    @include font-face('PFDinTextPro', 'PFDinTextPro-Bold', 700, italic);
@include font-face('Times New Roman', 'TimesNewRomanPS-BoldMT', bold);
@include font-face('Times New Roman', 'TimesNewRomanPS-BoldItalicMT', bold,italic);
@include font-face('Times New Roman', 'TimesNewRomanPSMT');
//@include font-face('Times New Roman', 'TimesNewRomanPS-ItalicMT',normal,italic);
@include font-face('Gotham Pro', 'GothamPro-Light',300);
@include font-face('Gotham Pro', 'GothamPro');
//@include font-face('Gotham Pro', 'GothamPro-LightItalic',300,italic);
//@include font-face('Gotham Pro', 'GothamPro-BoldItalic',bold,italic);
@include font-face('Gotham Pro', 'GothamPro-Italic',normal,italic);
//@include font-face('Gotham Pro', 'GothamPro-BlackItalic',900,italic);
@include font-face('Gotham Pro', 'GothamPro-Bold',bold);
@include font-face('Gotham Pro', 'GothamPro-Medium',500);
//@include font-face('Gotham Pro', 'GothamPro-Black',900);
//@include font-face('Gotham Pro', 'GothamPro-MediumItalic',500,italic);


/*/fonts*/

/* /base styles */

/* ctext */

    .ctext{line-height: 1.8;
        &.center{text-align: center;}
        h1{font-size: 36px;}
        h2{font-size: 32px;}
        h3{font-size: 28px;}
        h4{font-size: 24px;}
        h5{font-size: 20px;}
        h6{font-size: 16px;}
        p{margin-bottom: 10px;font-size: 14px;}
        ul{padding-left: 2em;
            li{position: relative;
                &:after{background-color: $zol;height: 5px;width: 5px;transform: rotate(45deg);content: '';position: absolute;top: 0.6em;left: -1.2em;}
            }
        }
        blockquote {
            font-family: $G;
            font-size: 16px;
            font-style: italic;
            margin: 1.2em 0;
            padding: 50px 20px 50px 120px;
            line-height: 1.45;
            position: relative;
            &:after{content: '';position: absolute;height: 100%;width: 1px;background-color: $grey;left:50px;top:0;}
            &:before{content: '';background: url("../images/blockquote.png") center center no-repeat;width: 27px;height: 19px;position: absolute;left:0;top:0;left: 80px;top: 40px}
            cite{opacity:0.7}
        }
    }

    .ctext-img{margin:0 -70px 30px;
        &.three{display: flex;flex-wrap:wrap;justify-content: space-between;margin-top: 30px;
            img{height: 450px;object-fit:cover;
                &:nth-child(1){width: 49%;}
                &:nth-child(2){width: 49%;}
                &:nth-child(3){width: 100%;margin-top: 20px;}
            }
        }
    }

/* /ctext */



/* styles for wp-admin panel */
body{
    &.customize-support{
        .wp-panel-show{top:32px;} //fixed elements (if their has top:0) must has class .wp-panel-show
    }
}
@media screen and (max-width: 782px){

    body{
        &.customize-support{
            .wp-panel-show{top:46px;}
        }
    }

    #wpadminbar{position: fixed!important;}

}
 /* /styles for wp-admin panel */

/*End Layout*/


// TITLE
.title{font-family: $T;
    h1,h2,h3,h4,h5{color:$black;margin:0 0 13px;font-size: 36px;font-weight: normal;}
    &.center{text-align: center;
        .plashka{margin:0 auto;}
    }
    &.only-left{
        .subtitle{color:$zol;}
        h1,h2,h3,h4,h5{opacity:0.8;}
        .plashka{
            .right{display: none;}
        }
    }
    &.white{
        .subtitle{color:$zol;opacity:0.7;
            &.white{color:white;margin-bottom: 11px;opacity:1;}
        }
        h1,h2,h3,h4,h5{color:white;}
    }
    a{text-decoration: none;
        &:after{display: none;}
    }
    .subtitle{font-size: 13px;text-transform: uppercase;opacity:0.7;margin-bottom: 6px;font-family: $G;
        &.small{text-transform:none;font-size: 15px;margin-bottom: 18px;font-family: $T;}
        &.big{font-size: 20px;font-family: $T;text-transform:none;margin-bottom: 18px;}
        &.gold{color:$zol;}
    }

    @media screen and (max-width: 767px){
        h1,h2,h3,h4,h5{margin-bottom: 9px;font-size: 31px;}
        .subtitle{font-size: 11px;margin-bottom: 0;}
    }
}

.plashka{width: 100%;max-width: 150px;position: relative;height: 7px;
    .left,.right{position: absolute;height: 1px;background-color: $zol;width: 43%;top: 3px;
        &:after{background-color: $zol;height: 5px;width: 5px;transform: rotate(45deg);content: '';position: absolute;top:-2px;}
    }
    .left{left:0px;
        &:after{right:0;}
    }
    .right{right:0px;
        &:after{left:0;}
    }
}

// input
.input{height: 50px;max-width: 250px;width: 100%;border:1px solid $black;font-size: 14px;margin: 0 auto 30px;font-family: $T;padding:0 15px;
    &.error{border-color:#ff0000;
        &+.errorText{color:#ff0000;position: absolute;text-align: center;left:50%;bottom:10px;transform: translateX(-50%);font-size: 12px;min-width:100%;}
    }
    &.center{text-align: center;}
    &.inverse{border-color:white;background-color: transparent;color:white;
        &.error{border-color: #ff0000;}
    }
}
// textarea
.textarea{border:1px solid $black;font-size: 14px;margin: 0 auto 30px;font-family: $T;padding:15px 15px;min-height:150px;resize:none;width: 100%;max-width: 250px;
    &.error{border-color:#ff0000;
        &+.errorText{color:$red;position: absolute;text-align: center;left:50%;bottom:10px;transform: translateX(-50%);font-size: 12px;min-width:100%;}
    }
}
input, textarea{
    &::-webkit-input-placeholder {color:#d6d6d6;}
    &:-moz-placeholder {color:#d6d6d6;opacity:1;}
    &::-moz-placeholder {color:#d6d6d6;opacity:1;}
    &:-ms-input-placeholder {color:#d6d6d6;}
}

//checkbox
.checkbox{display: block;position: relative;cursor: pointer;user-select: none;height: 25px;width: 25px;
    input{position: absolute;opacity: 0;cursor:pointer;}
    .checkmark{ position: absolute;top: 0;left: 0;width: 100%;height: 100%;}

    &:hover input ~ .checkmark {background-color: #fafafa;}
    input.error ~ .checkmark:before {border-color:red !important;}
    input:checked ~ .checkmark {}

    .checkmark:after {content: "";position: absolute;display: none;}

    input:checked ~ .checkmark:after {display: block;}

    /* Style the checkmark/indicator */
    .checkmark:after {left: 8px;top: 5px;width: 7px;height: 10px;border: solid #3f3d3d;border-width: 0 3px 3px 0;transform: rotate(45deg);}
    .checkmark:before {width: 100%;height: 100%;border:1px solid #3f3f3f;content: '';position: absolute;left:0;right:0;}
}


//button
.button{height: 50px;max-width: 250px;width: 100%;display: flex;align-items: center;justify-content: center;text-align: center;border:1px solid transparent;transition:all ease .3s;border-radius:1px;padding:5px;color:white;background-color: $zol;text-decoration: none;backface-visibility: hidden;transform: translateZ(0);
    &:after{display: none;}
    &:hover{background: $grey;}
    &:active{color: $grey;background-color: #fff;border-color:$grey;}

    &.black{color:white;background-color: $black;
        &:hover{color:white;background-color: $zol;}
        &:active{color:$zol;background-color: white;border-color:$zol;}
    }

    &.width-border{color: $grey;background-color: #fff;border-color:$grey;
        &:hover{color:white;background-color: $zol;border-color:transparent;}
        &:active{color:$zol;background-color: white;border-color:$zol;}
    }

    &.white{color:$grey;background-color: #fff;
        &:hover{color:white;background-color: $zol;border-color:transparent;}
        &:active{color:$zol;background-color: white;border-color:$zol;}
    }
}

//href
a:not(.no-style),.link{color:inherit;text-decoration: none;position: relative;transition: all ease .3s;
    &:after{content: '';bottom:-1px;left:0;background-color: currentColor;position: absolute;height: 1px;width: 100%;transition: all ease .3s;}
    &:hover{color:$zol;
        &:after{background-color: $zol;}
    }
    &:active{
        &:after{bottom:5px;}
    }
}