/* base styles*/
*, *:before, *:after {
  box-sizing: border-box;
  outline: none;
}

*, body, html {
  -webkit-text-size-adjust: none;
  -webkit-appearance: none;
}

html {
  height: 100%;
}

body {
  min-height: 100%;
  width: 100%;
  min-width: 320px;
  font-family: 'Gotham Pro',arial,sans-serif;
  background: #fff;
  color: #413f3f;
  position: relative;
  font-size: 14px;
  line-height: 1;
}

img {
  vertical-align: bottom;
  border: 0;
  max-width: 100%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/* helping adds*/
.cfix:after {
  content: '';
  display: block;
  height: 0;
  font-size: 1px;
  line-height: 1px;
  overflow: hidden;
  clear: both;
}

.hidden-block {
  position: absolute;
  left: -99999em;
  top: -99999em;
}

html.scroll-lock, body.scroll-lock {
  overflow: hidden;
}

/* /helping adds*/
/* wraps styling*/
.mbox {
  max-width: 1430px;
  padding: 0 15px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  z-index: 1;
}

.mbox.big {
  max-width: 1750px;
}

.mbox.mini {
  max-width: 1030px;
}

/* /wraps styling*/
/*fonts*/
@font-face {
  font-family: "Times New Roman";
  src: url("../fonts/TimesNewRomanPS-BoldMT.eot");
  src: url("../fonts/TimesNewRomanPS-BoldMT.eot?#iefix") format("embedded-opentype"), url("../fonts/TimesNewRomanPS-BoldMT.woff") format("woff"), url("../fonts/TimesNewRomanPS-BoldMT.ttf") format("truetype"), url("../fonts/TimesNewRomanPS-BoldMT.svg#Times New Roman") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Times New Roman";
  src: url("../fonts/TimesNewRomanPS-BoldItalicMT.eot");
  src: url("../fonts/TimesNewRomanPS-BoldItalicMT.eot?#iefix") format("embedded-opentype"), url("../fonts/TimesNewRomanPS-BoldItalicMT.woff") format("woff"), url("../fonts/TimesNewRomanPS-BoldItalicMT.ttf") format("truetype"), url("../fonts/TimesNewRomanPS-BoldItalicMT.svg#Times New Roman") format("svg");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Times New Roman";
  src: url("../fonts/TimesNewRomanPSMT.eot");
  src: url("../fonts/TimesNewRomanPSMT.eot?#iefix") format("embedded-opentype"), url("../fonts/TimesNewRomanPSMT.woff") format("woff"), url("../fonts/TimesNewRomanPSMT.ttf") format("truetype"), url("../fonts/TimesNewRomanPSMT.svg#Times New Roman") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamPro-Light.eot");
  src: url("../fonts/GothamPro-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Light.woff") format("woff"), url("../fonts/GothamPro-Light.ttf") format("truetype"), url("../fonts/GothamPro-Light.svg#Gotham Pro") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamPro.eot");
  src: url("../fonts/GothamPro.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro.woff") format("woff"), url("../fonts/GothamPro.ttf") format("truetype"), url("../fonts/GothamPro.svg#Gotham Pro") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamPro-Italic.eot");
  src: url("../fonts/GothamPro-Italic.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Italic.woff") format("woff"), url("../fonts/GothamPro-Italic.ttf") format("truetype"), url("../fonts/GothamPro-Italic.svg#Gotham Pro") format("svg");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamPro-Bold.eot");
  src: url("../fonts/GothamPro-Bold.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Bold.woff") format("woff"), url("../fonts/GothamPro-Bold.ttf") format("truetype"), url("../fonts/GothamPro-Bold.svg#Gotham Pro") format("svg");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Gotham Pro";
  src: url("../fonts/GothamPro-Medium.eot");
  src: url("../fonts/GothamPro-Medium.eot?#iefix") format("embedded-opentype"), url("../fonts/GothamPro-Medium.woff") format("woff"), url("../fonts/GothamPro-Medium.ttf") format("truetype"), url("../fonts/GothamPro-Medium.svg#Gotham Pro") format("svg");
  font-weight: 500;
  font-style: normal;
}

/*/fonts*/
/* /base styles */
/* ctext */
.ctext {
  line-height: 1.8;
}

.ctext.center {
  text-align: center;
}

.ctext h1 {
  font-size: 36px;
}

.ctext h2 {
  font-size: 32px;
}

.ctext h3 {
  font-size: 28px;
}

.ctext h4 {
  font-size: 24px;
}

.ctext h5 {
  font-size: 20px;
}

.ctext h6 {
  font-size: 16px;
}

.ctext p {
  margin-bottom: 10px;
  font-size: 14px;
}

.ctext ul {
  padding-left: 2em;
}

.ctext ul li {
  position: relative;
}

.ctext ul li:after {
  background-color: #cab387;
  height: 5px;
  width: 5px;
  transform: rotate(45deg);
  content: '';
  position: absolute;
  top: 0.6em;
  left: -1.2em;
}

.ctext blockquote {
  font-family: "Gotham Pro";
  font-size: 16px;
  font-style: italic;
  margin: 1.2em 0;
  padding: 50px 20px 50px 120px;
  line-height: 1.45;
  position: relative;
}

.ctext blockquote:after {
  content: '';
  position: absolute;
  height: 100%;
  width: 1px;
  background-color: #707070;
  left: 50px;
  top: 0;
}

.ctext blockquote:before {
  content: '';
  background: url("../images/blockquote.png") center center no-repeat;
  width: 27px;
  height: 19px;
  position: absolute;
  left: 0;
  top: 0;
  left: 80px;
  top: 40px;
}

.ctext blockquote cite {
  opacity: 0.7;
}

.ctext-img {
  margin: 0 -70px 30px;
}

.ctext-img.three {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 30px;
}

.ctext-img.three img {
  height: 450px;
  object-fit: cover;
}

.ctext-img.three img:nth-child(1) {
  width: 49%;
}

.ctext-img.three img:nth-child(2) {
  width: 49%;
}

.ctext-img.three img:nth-child(3) {
  width: 100%;
  margin-top: 20px;
}

/* /ctext */
/* styles for wp-admin panel */
body.customize-support .wp-panel-show {
  top: 32px;
}

@media screen and (max-width: 782px) {
  body.customize-support .wp-panel-show {
    top: 46px;
  }
  #wpadminbar {
    position: fixed !important;
  }
}

/* /styles for wp-admin panel */
/*End Layout*/
.title {
  font-family: "Times New Roman", sans-serif;
}

.title h1, .title h2, .title h3, .title h4, .title h5 {
  color: #413f3f;
  margin: 0 0 13px;
  font-size: 36px;
  font-weight: normal;
}

.title.center {
  text-align: center;
}

.title.center .plashka {
  margin: 0 auto;
}

.title.only-left .subtitle {
  color: #cab387;
}

.title.only-left h1, .title.only-left h2, .title.only-left h3, .title.only-left h4, .title.only-left h5 {
  opacity: 0.8;
}

.title.only-left .plashka .right {
  display: none;
}

.title.white .subtitle {
  color: #cab387;
  opacity: 0.7;
}

.title.white .subtitle.white {
  color: white;
  margin-bottom: 11px;
  opacity: 1;
}

.title.white h1, .title.white h2, .title.white h3, .title.white h4, .title.white h5 {
  color: white;
}

.title a {
  text-decoration: none;
}

.title a:after {
  display: none;
}

.title .subtitle {
  font-size: 13px;
  text-transform: uppercase;
  opacity: 0.7;
  margin-bottom: 6px;
  font-family: "Gotham Pro";
}

.title .subtitle.small {
  text-transform: none;
  font-size: 15px;
  margin-bottom: 18px;
  font-family: "Times New Roman", sans-serif;
}

.title .subtitle.big {
  font-size: 20px;
  font-family: "Times New Roman", sans-serif;
  text-transform: none;
  margin-bottom: 18px;
}

.title .subtitle.gold {
  color: #cab387;
}

@media screen and (max-width: 767px) {
  .title h1, .title h2, .title h3, .title h4, .title h5 {
    margin-bottom: 9px;
    font-size: 31px;
  }
  .title .subtitle {
    font-size: 11px;
    margin-bottom: 0;
  }
}

.plashka {
  width: 100%;
  max-width: 150px;
  position: relative;
  height: 7px;
}

.plashka .left, .plashka .right {
  position: absolute;
  height: 1px;
  background-color: #cab387;
  width: 43%;
  top: 3px;
}

.plashka .left:after, .plashka .right:after {
  background-color: #cab387;
  height: 5px;
  width: 5px;
  transform: rotate(45deg);
  content: '';
  position: absolute;
  top: -2px;
}

.plashka .left {
  left: 0px;
}

.plashka .left:after {
  right: 0;
}

.plashka .right {
  right: 0px;
}

.plashka .right:after {
  left: 0;
}

.input {
  height: 50px;
  max-width: 250px;
  width: 100%;
  border: 1px solid #413f3f;
  font-size: 14px;
  margin: 0 auto 30px;
  font-family: "Times New Roman", sans-serif;
  padding: 0 15px;
}

.input.error {
  border-color: #ff0000;
}

.input.error + .errorText {
  color: #ff0000;
  position: absolute;
  text-align: center;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  font-size: 12px;
  min-width: 100%;
}

.input.center {
  text-align: center;
}

.input.inverse {
  border-color: white;
  background-color: transparent;
  color: white;
}

.input.inverse.error {
  border-color: #ff0000;
}

.textarea {
  border: 1px solid #413f3f;
  font-size: 14px;
  margin: 0 auto 30px;
  font-family: "Times New Roman", sans-serif;
  padding: 15px 15px;
  min-height: 150px;
  resize: none;
  width: 100%;
  max-width: 250px;
}

.textarea.error {
  border-color: #ff0000;
}

.textarea.error + .errorText {
  color: #9a0a0a;
  position: absolute;
  text-align: center;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  font-size: 12px;
  min-width: 100%;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #d6d6d6;
}

input:-moz-placeholder, textarea:-moz-placeholder {
  color: #d6d6d6;
  opacity: 1;
}

input::-moz-placeholder, textarea::-moz-placeholder {
  color: #d6d6d6;
  opacity: 1;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #d6d6d6;
}

.checkbox {
  display: block;
  position: relative;
  cursor: pointer;
  user-select: none;
  height: 25px;
  width: 25px;
  /* Style the checkmark/indicator */
}

.checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.checkbox:hover input ~ .checkmark {
  background-color: #fafafa;
}

.checkbox input.error ~ .checkmark:before {
  border-color: red !important;
}

.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 8px;
  top: 5px;
  width: 7px;
  height: 10px;
  border: solid #3f3d3d;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.checkbox .checkmark:before {
  width: 100%;
  height: 100%;
  border: 1px solid #3f3f3f;
  content: '';
  position: absolute;
  left: 0;
  right: 0;
}

.button {
  height: 50px;
  max-width: 250px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid transparent;
  transition: all ease .3s;
  border-radius: 1px;
  padding: 5px;
  color: white;
  background-color: #cab387;
  text-decoration: none;
  backface-visibility: hidden;
  transform: translateZ(0);
}

.button:after {
  display: none;
}

.button:hover {
  background: #707070;
}

.button:active {
  color: #707070;
  background-color: #fff;
  border-color: #707070;
}

.button.black {
  color: white;
  background-color: #413f3f;
}

.button.black:hover {
  color: white;
  background-color: #cab387;
}

.button.black:active {
  color: #cab387;
  background-color: white;
  border-color: #cab387;
}

.button.width-border {
  color: #707070;
  background-color: #fff;
  border-color: #707070;
}

.button.width-border:hover {
  color: white;
  background-color: #cab387;
  border-color: transparent;
}

.button.width-border:active {
  color: #cab387;
  background-color: white;
  border-color: #cab387;
}

.button.white {
  color: #707070;
  background-color: #fff;
}

.button.white:hover {
  color: white;
  background-color: #cab387;
  border-color: transparent;
}

.button.white:active {
  color: #cab387;
  background-color: white;
  border-color: #cab387;
}

a:not(.no-style), .link {
  color: inherit;
  text-decoration: none;
  position: relative;
  transition: all ease .3s;
}

a:not(.no-style):after, .link:after {
  content: '';
  bottom: -1px;
  left: 0;
  background-color: currentColor;
  position: absolute;
  height: 1px;
  width: 100%;
  transition: all ease .3s;
}

a:not(.no-style):hover, .link:hover {
  color: #cab387;
}

a:not(.no-style):hover:after, .link:hover:after {
  background-color: #cab387;
}

a:not(.no-style):active:after, .link:active:after {
  bottom: 5px;
}
